/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import favicon from "../images/favicon.png";

function SEO({ description, lang, meta, keywords, title, robots }) {
  const { site, pandaCustomSettings } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        pandaCustomSettings {
          pg_text_field_addressCountry
          pg_text_field_addressLocality
          pg_text_field_addressRegion
          pg_text_field_postalCode
          pg_text_field_streetAddress
          pg_text_field_award
          pg_text_field_name
          pg_text_field_contactType
          pg_text_field_email
          pg_text_field_telephone
          pg_text_field_closes
          pg_text_field_dayOfWeek
          pg_text_field_opens
          pg_text_field_founder1givenName
          pg_text_field_founder1familyName
          pg_text_field_founder1jobTitle
          pg_text_field_founder1email
          pg_text_field_founder2givenName
          pg_text_field_founder2familyName
          pg_text_field_founder2jobTitle
          pg_text_field_founder2email
          pg_text_field_foundingDate
          pg_text_field_knowsAbout
          pg_text_field_knowsLanguage
          pg_text_field_legalName
          pg_text_field_logo
          pg_text_field_numberOfEmployees
          pg_text_field_vatID
          pg_text_field_social1
          pg_text_field_social2
          pg_text_field_social3
          pg_text_field_social4
          pg_text_field_social5
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: pandaCustomSettings.pg_text_field_name,
    legalName: pandaCustomSettings.pg_text_field_legalName,
    url: "https://pandagroup.co",
    logo: pandaCustomSettings.pg_text_field_logo,
    foundingDate: pandaCustomSettings.pg_text_field_foundingDate,
    founders: [
      {
        "@type": "Person",
        givenName: pandaCustomSettings.pg_text_field_founder1givenName,
        familyName: pandaCustomSettings.pg_text_field_founder1familyName,
        jobTitle: pandaCustomSettings.pg_text_field_founder1jobTitle,
        email: pandaCustomSettings.pg_text_field_founder1email
      },
      {
        "@type": "Person",
        givenName: pandaCustomSettings.pg_text_field_founder2givenName,
        familyName: pandaCustomSettings.pg_text_field_founder2familyName,
        jobTitle: pandaCustomSettings.pg_text_field_founder2jobTitle,
        email: pandaCustomSettings.pg_text_field_founder2email
      }
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: pandaCustomSettings.pg_text_field_streetAddress,
      addressLocality: pandaCustomSettings.pg_text_field_addressLocality,
      addressRegion: pandaCustomSettings.pg_text_field_addressRegion,
      postalCode: pandaCustomSettings.pg_text_field_postalCode,
      addressCountry: pandaCustomSettings.pg_text_field_addressCountry
    },
    contactPoint: {
      "@type": "ContactPoint",
      contactType: pandaCustomSettings.pg_text_field_contactType,
      telephone: pandaCustomSettings.pg_text_field_telephone,
      email: pandaCustomSettings.pg_text_field_email
    },
    sameAs: [
      pandaCustomSettings.pg_text_field_social1,
      pandaCustomSettings.pg_text_field_social2,
      pandaCustomSettings.pg_text_field_social3
    ],
    knowsAbout: pandaCustomSettings.pg_text_field_knowsAbout,
    knowsLanguage: pandaCustomSettings.pg_text_field_knowsLanguage,
    numberOfEmployees: pandaCustomSettings.pg_text_field_numberOfEmployees,
    vatID: pandaCustomSettings.pg_text_field_vatID
  };

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={ReactHtmlParser(title)}
      titleTemplate="%s"
      link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: ReactHtmlParser(title)
        },
        {
          property: `og:description`,
          content: ReactHtmlParser(metaDescription)
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: ReactHtmlParser(site.siteMetadata.author)
        },
        {
          name: `twitter:title`,
          content: ReactHtmlParser(title)
        },
        {
          name: `twitter:description`,
          content: ReactHtmlParser(metaDescription)
        },
        {
          name: `robots`,
          content: typeof robots !== "undefined" ? robots : `INDEX,FOLLOW`
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
